import { useAppDispatch, useAppSelector } from 'store/hooks'

import { orderDecision } from 'modules/orders/slices/orders'

import { TableOverlayButton } from 'ui/table/components'
import { CloseOutlinedIcon, CheckIcon } from 'ui/icons'
import { Box, Tooltip, Typography } from 'ui'
import { useAppTheme } from 'ui/theme'

import type { FC } from 'react'
import type { OrderType } from 'modules/orders/types'

interface Props {
  row: OrderType
  rejectOrder: (details: OrderType) => void
}

const OrdersTableConfirmActions: FC<Props> = ({ row, rejectOrder }) => {
  const theme = useAppTheme()
  const dispatch = useAppDispatch()
  const details = useAppSelector(state => state.orders.orders.details)

  return (
    <Box display='flex'>
      <Tooltip
        placement='top'
        overlay={
          <Typography as='p' textAlign='center' lineHeight='m'>
            Подтвердить <br /> заказ
          </Typography>
        }
      >
        <TableOverlayButton
          onClick={() => {
            dispatch(
              orderDecision({
                refresh: 'both',
                solution: 'accept',
                details: details.id !== row.id ? row : null,
              }),
            )
          }}
        >
          <CheckIcon color={theme.colors.icon.black} />
        </TableOverlayButton>
      </Tooltip>
      <Tooltip
        placement='top'
        overlay={
          <Typography as='p' textAlign='center' lineHeight='m'>
            Отменить <br /> заказ
          </Typography>
        }
      >
        <TableOverlayButton
          onClick={() => {
            rejectOrder(details.id !== row.id ? row : details)
          }}
        >
          <CloseOutlinedIcon color={theme.colors.icon.black} />
        </TableOverlayButton>
      </Tooltip>
    </Box>
  )
}

export { OrdersTableConfirmActions }
