import { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import { RenderRoutesProps } from 'modules/common/types/interfaces'

import { NotFound } from 'modules/core/components'

const RouteWithSubRoutes = route => (
  <Route path={route.path} exact={route.exact}>
    {route.routes ? (
      <route.component routes={route.routes} />
    ) : (
      <route.component />
    )}
  </Route>
)

const RenderRoutes: FC<RenderRoutesProps> = ({ routes }) => (
  <Switch>
    {routes.map(route => (
      <RouteWithSubRoutes key={route.key} {...route} />
    ))}
    <Route component={NotFound} />
  </Switch>
)

export { RenderRoutes }
