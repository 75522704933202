import { Fragment } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  useAutoRejectModal,
  usePackagesModal,
  useOrdersTable,
  useRejectModal,
} from 'modules/orders/hooks'

import {
  PackagesCountModal,
  RejectReasonModal,
} from 'modules/orders/pages/OrderDetails/components'
import { AutoRejectModal } from 'modules/orders/pages/Orders/components'

import { changeOrdersSorting } from 'modules/orders/slices/orders'

import { Table as BaseTable } from 'ui'

import {
  getDeliveringColumns,
  getCompletedColumns,
  getWaitingColumns,
  getReadyColumns,
  getAllColumns,
} from './getColumns'

import { ORDERS_FILTER_STATUS } from 'modules/orders/types/enums'

import type { FC } from 'react'
import type { OrderStatusType } from 'modules/orders/types'

const Table: FC = () => {
  const dispatch = useAppDispatch()
  const rejectModal = useRejectModal()
  const packagesModal = usePackagesModal()
  const autoRejectModal = useAutoRejectModal()

  const table = useOrdersTable({
    onRejectOrder: rejectModal.onShow,
    onChangeOrderPackages: packagesModal.onShow,
  })

  const { list, filter, sorting } = useAppSelector(state => state.orders.orders)
  const pickupPoints = useAppSelector(state => state.profile.pickupPoints.list)

  const getColumns = (status: OrderStatusType) => {
    const columnsProps = {
      onChangeSorting: sort => dispatch(changeOrdersSorting(sort)),
      search: filter.search,
      pickupPoints,
      sorting,
    }

    switch (status) {
      case ORDERS_FILTER_STATUS.ON_ASSEMBLY:
      case ORDERS_FILTER_STATUS.REASSEMBLY:
      case ORDERS_FILTER_STATUS.ASSEMBLED:
        return getReadyColumns(columnsProps)

      case ORDERS_FILTER_STATUS.WAITING_CONFIRMATION:
        return getWaitingColumns(columnsProps)

      case ORDERS_FILTER_STATUS.DELIVERING:
        return getDeliveringColumns(columnsProps)

      case ORDERS_FILTER_STATUS.COMPLETED:
        return getCompletedColumns(columnsProps)

      default:
        return getAllColumns(columnsProps)
    }
  }

  return (
    <Fragment>
      <BaseTable
        {...table}
        data={list}
        rowKey='id'
        columns={getColumns(filter.status)}
      />

      <RejectReasonModal
        visible={rejectModal.visible}
        details={rejectModal.details}
        onClose={rejectModal.onClose}
      />

      <PackagesCountModal
        visible={packagesModal.visible}
        orderId={packagesModal.orderId}
        onClose={packagesModal.onClose}
      />

      <AutoRejectModal
        visible={autoRejectModal.visible}
        onClose={autoRejectModal.onClose}
        onOk={autoRejectModal.onOk}
      />
    </Fragment>
  )
}

export { Table }
