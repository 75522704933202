import { FC, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { logout } from 'modules/auth/slices/credentials'

import { ProfileMenu } from './components'

import { Dropdown, Image, Modal, Typography, Ellipsis } from 'ui'
import { ChevronIcon } from 'ui/icons'
import { styled } from 'ui/theme'

import profileImageScr from 'assets/images/profile.png'

const { Body } = Typography

const ProfileButton = styled.button`
  background-color: ${props => props.theme.colors.main.background.white};
  border: none;
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
  cursor: pointer;
  align-items: center;

  height: 100%;

  padding: 0 16px;

  &:hover {
    background-color: ${props => props.theme.colors.main.background.gray};
  }
`

const ProfileWidget: FC = () => {
  const { logo_url, store_name } = useAppSelector(
    state => state.profile.profile.profile,
  )

  const [visible, setVisible] = useState(false)
  const dispatch = useAppDispatch()

  return (
    <>
      <Dropdown
        overlay={ProfileMenu({ setVisible })}
        placement='bottomRight'
        overlayStyle={{ minWidth: '300px' }}
      >
        <ProfileButton>
          <Ellipsis as={Body} maxWidth='512px'>
            {store_name}
          </Ellipsis>
          <Image
            src={logo_url || profileImageScr}
            borderRadius='r'
            height={48}
            width={48}
            isBg
          />
          <ChevronIcon />
        </ProfileButton>
      </Dropdown>

      <Modal
        title='Выйти'
        okText='Выйти'
        width='350px'
        visible={visible}
        onCancel={() => setVisible(false)}
        onClose={() => setVisible(false)}
        onOk={() => dispatch(logout())}
      >
        <Body as='p' mb='16px'>
          Вы уверены, что хотите выйти?
        </Body>
      </Modal>
    </>
  )
}

export { ProfileWidget }
