import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { setPackagesCount } from 'modules/orders/slices/orders'

import { matchNumber } from 'utils/textValidation'

import { FormField, Modal, Typography } from 'ui'

import { ORDERS_FILTER_STATUS } from 'modules/orders/types/enums'

import type { FC } from 'react'

interface PackagesCountModalProps {
  visible: boolean
  orderId?: string
  onClose: VoidFunction
  showTip?: VoidFunction
}

const { Caption, Body } = Typography

const PackagesCountModal: FC<PackagesCountModalProps> = ({
  visible,
  onClose,
  showTip,
  orderId,
}) => {
  const dispatch = useAppDispatch()

  const { details, loadingOfDecision } = useAppSelector(
    state => state.orders.orders,
  )

  const [count, setCount] = useState('')

  const handleOk = () => {
    dispatch(
      setPackagesCount({
        count,
        orderId,
        decision:
          details.status === ORDERS_FILTER_STATUS.ON_ASSEMBLY ||
          details.status === ORDERS_FILTER_STATUS.REASSEMBLY,
        closeCb: onClose,
        onShowTip: showTip,
      }),
    )
  }

  return (
    <Modal
      title='Грузовые места'
      okText='отправить'
      width='365px'
      disabled={count.length === 0}
      okLoading={loadingOfDecision}
      visible={visible}
      onCancel={onClose}
      onClose={onClose}
      onOk={handleOk}
    >
      <Body as='p' mb='4px'>
        Укажите количество грузовых мест
      </Body>
      <Caption as='p' mb='16px' color='text.secondaryBlack'>
        Грузовые места – это количество коробок или сейф–пакетов, которые вы
        планируете передать курьеру Airba по данному заказу FBS
      </Caption>

      <FormField
        placeholder='Введите количество грузовых мест'
        size='large'
        value={count}
        onInput={matchNumber}
        onChange={e => setCount(e.target.value)}
        onClear={() => setCount('')}
      />
    </Modal>
  )
}

export { PackagesCountModal }
