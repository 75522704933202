import { FC, useState } from 'react'
import { useAccountStatus } from 'modules/auth/hooks'

import {
  PartnersSelection,
  NewPasswordForm,
  AccountStatus,
  PasswordForm,
  PhoneForm,
  SmsForm,
} from 'modules/auth/components'

import { ACCOUNT_STATUS } from 'modules/auth/types/enums'

const SignInForm: FC = () => {
  const [phone, setPhone] = useState<string>('')
  const { status, errorStatus, setErrorStatus } = useAccountStatus()

  switch (status) {
    case ACCOUNT_STATUS.ACTIVE:
      return (
        <PasswordForm
          phone={phone}
          error={errorStatus}
          setErrorStatus={setErrorStatus}
        />
      )
    case ACCOUNT_STATUS.INACTIVE:
      return (
        <SmsForm
          phone={phone}
          error={errorStatus}
          setErrorStatus={setErrorStatus}
        />
      )
    case ACCOUNT_STATUS.NO_PASSWORD:
      return (
        <NewPasswordForm error={errorStatus} setErrorStatus={setErrorStatus} />
      )
    case ACCOUNT_STATUS.WAITING:
      return <AccountStatus />
    case ACCOUNT_STATUS.PARTNER_SELECTION:
      return <PartnersSelection />
    default:
      return (
        <PhoneForm
          phone={phone}
          setPhone={setPhone}
          error={errorStatus}
          setErrorStatus={setErrorStatus}
        />
      )
  }
}

export default SignInForm
