import { Orders, OrderDetails } from 'modules/orders/pages'

import { getDashedStr } from 'utils/getDashedStr'

import { IRoute } from 'modules/common/types/interfaces'

enum KEYS {
  ORDERS = 'ORDERS',
  ORDER_DETAILS = 'ORDER_DETAILS',
  REFUND_DETAILS = 'REFUND_DETAILS',
}

export const getRoutes = (key: string): IRoute[] => [
  {
    path: '/orders',
    exact: true,
    key: getDashedStr(key, KEYS.ORDERS),
    component: Orders,
  },
  // {
  //   path: '/orders/refunds',
  //   exact: true,
  //   key: getDashedStr(key, KEYS.REFUNDS),
  //   component: Refunds,
  // },
  {
    path: '/orders/:orderId',
    exact: true,
    key: getDashedStr(key, KEYS.ORDER_DETAILS),
    component: OrderDetails,
  },
]
