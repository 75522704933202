import { Fragment } from 'react'

import { formatCurrency } from 'utils/textFormatting'

import { Typography, Condition, Checkbox, Anchor, Image, Box } from 'ui'
import { DeniedIcon } from 'ui/icons'
import { ifProp } from 'styled-tools'
import { styled } from 'ui/theme'

import { env } from 'settings/env'

import type { ChangeEvent } from 'react'
import type { CbxState } from 'modules/common/types/interfaces'
import type { OrderProductsType } from 'modules/orders/types'
import type { ChangeCbx } from 'modules/common/types'
import type { ColumnsType } from 'ui/table/types'

interface ImageContainerProps {
  removed: boolean
}

interface IColumns {
  cbxState: CbxState
  onChangeCbx: ChangeCbx
  onCheckAllCbxChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const ImageContainer = styled.div<ImageContainerProps>`
  position: relative;
  height: 44px;
  width: 44px;

  img {
    ${ifProp('removed', 'opacity: 0.5')};
    transition: opacity 0.2s;
  }

  .__image-overlay {
    opacity: ${props => (props.removed ? 1 : 0)};
    background: ${props => props.theme.colors.main.background.gray};
    transition: opacity 0.2s;
    padding: 2px;
    font-size: 0;
    line-height: 0;
    border-radius: 50%;
    position: absolute;
    top: -2px;
    right: -4px;
  }
`

const renderText = (text: string, row: OrderProductsType) => (
  <Typography
    color={row.removed || !row.is_active ? 'text.secondaryBlack' : 'text.black'}
  >
    {text}
  </Typography>
)

export const columns = ({
  cbxState,
  onChangeCbx,
  onCheckAllCbxChange,
}: IColumns): ColumnsType<OrderProductsType> => [
  {
    title: (
      <Checkbox
        checked={cbxState.checkAll}
        onChange={onCheckAllCbxChange}
        indeterminate={cbxState.indeterminate}
      />
    ),
    dataIndex: 'sku',
    key: 'sku',
    width: 56,
    render: id => (
      <Checkbox
        id={id}
        value={id}
        onChange={() => onChangeCbx(id)}
        checked={cbxState.checkedList.some(itemId => itemId === id)}
      />
    ),
  },
  {
    title: '',
    dataIndex: 'image_url',
    key: 'image_url',
    width: 66,
    render: (url, row) => (
      <ImageContainer removed={row.removed || !row.is_active}>
        <Image src={url} width='100%' height='100%' bgSize='contain' isBg />
        <Box className='__image-overlay'>
          <DeniedIcon color='#F15515' width={16} height={16} />
        </Box>
      </ImageContainer>
    ),
  },
  {
    title: 'Артикул',
    dataIndex: 'sku',
    key: 'sku',
    width: 100,
    render: renderText,
  },
  {
    title: 'Ваш артикул',
    dataIndex: 'merchant_sku',
    key: 'merchant_sku',
    ellipsis: true,
    width: 140,
    render: renderText,
  },
  {
    title: 'Название в Airba магазине',
    dataIndex: ['title', 'ru'],
    key: 'title',
    // width: 256,
    ellipsis: true,
    render: (title, row) => (
      <Anchor
        target='_blank'
        rel='noreferrer'
        href={`${env.CUSTOMER_BASE_URL}/product/${row.sku}`}
      >
        {title}
      </Anchor>
    ),
  },
  {
    title: 'Цена за ед. товара',
    dataIndex: 'price',
    key: 'price',
    width: 205,
    align: 'right',
    render: (price, row) => renderText(formatCurrency(price), row),
  },
  {
    title: 'Кол–во',
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'right',
    width: 120,
    render: (quantity, row) => (
      <Fragment>
        <Typography
          as='p'
          color={
            row.removed || !row.is_active ? 'text.secondaryBlack' : 'text.black'
          }
        >
          {quantity} шт.
        </Typography>
        <Condition match={row.initial_quantity !== quantity}>
          <Typography
            as='p'
            mt='4px'
            lineHeight='xs'
            fontSize='caption.m'
            color='text.secondaryBlack'
          >
            <del>{row.initial_quantity} шт.</del>
          </Typography>
        </Condition>
      </Fragment>
    ),
  },
  {
    title: 'Сумма',
    dataIndex: 'total_price',
    key: 'total_price',
    width: 205,
    align: 'right',
    render: (price, row) =>
      renderText(formatCurrency(Number(row.price) * Number(row.quantity)), row),
  },
]
