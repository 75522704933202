import { useState } from 'react'
import { useAppSelector } from 'store/hooks'

import { useTableCbx } from 'ui/table/useTableCbx'
import { Condition, Box } from 'ui'
import {
  RejectReasonModal,
  TotalPrice,
  Decisions,
  Table,
} from 'modules/orders/pages/OrderDetails/components'

import type { FC } from 'react'

const OrderDetailsTable: FC = () => {
  const details = useAppSelector(state => state.orders.orders.details)

  const { onCheckAllCbxChange, onChangeCbx, onResetCbx, cbxState } =
    useTableCbx(details.products, 'sku')

  const [rejectModalVisible, setRejectModalVisible] = useState(false)

  const showRejectModal = () => {
    setRejectModalVisible(true)
  }

  const closeRejectModal = () => {
    setRejectModalVisible(false)
  }

  return (
    <Box>
      <Decisions
        onResetCbx={onResetCbx}
        showRejectModal={showRejectModal}
        rejectModalVisible={rejectModalVisible}
      />

      <Condition match={details.products.length !== 0}>
        <TotalPrice products={details.products} />
      </Condition>

      <Table
        details={details}
        cbxState={cbxState}
        onChangeCbx={onChangeCbx}
        products={details.products}
        onResetCbx={onResetCbx}
        onCheckAllCbxChange={onCheckAllCbxChange}
      />

      <RejectReasonModal
        visible={rejectModalVisible}
        onClose={closeRejectModal}
      />
    </Box>
  )
}

export { OrderDetailsTable }
