import { Component, ReactNode } from 'react'
import { connect } from 'react-redux'

import { fetchLastSuccessfulUpload } from 'modules/products/slices/productsFeed'
import { fetchContacts } from 'modules/common/slices/contacts'
import { fetchProfile } from 'modules/profile/slices/profile'
import { fetchCities } from 'modules/common/slices/cities'

import { RootState, AppDispatch } from 'store/configureStore'
import { History } from 'history'

interface PreloadProps {
  token?: string
  redirect_denied?: boolean
  children: ReactNode
  history: History
  onAuth?: VoidFunction
  onMain?: VoidFunction
  onFetchLastSuccessfulUpload?: VoidFunction
  onFetchContacts?: VoidFunction
  onFetchProfile?: VoidFunction
  onFetchCities?: VoidFunction
}

class PreloadProvider extends Component<PreloadProps> {
  componentDidMount() {
    const {
      token,
      onAuth,
      onMain,
      onFetchLastSuccessfulUpload,
      onFetchContacts,
      onFetchProfile,
      onFetchCities,
    } = this.props

    onFetchContacts()
    onFetchCities()

    if (!!token) {
      onFetchLastSuccessfulUpload()
      onFetchProfile()
    } else if (
      !/^\/auth/.test(window.location.pathname) &&
      !/^\/faq/.test(window.location.pathname)
    ) {
      onAuth()
    }

    if (token && /^\/auth/.test(window.location.pathname)) {
      onMain()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      token,
      redirect_denied,
      onAuth,
      onMain,
      onFetchLastSuccessfulUpload,
      onFetchProfile,
    } = this.props

    if (!prevProps.token && token && !redirect_denied) {
      onFetchLastSuccessfulUpload()
      onFetchProfile()
      onMain()
    }

    if (prevProps.token && !token) {
      onAuth()
    }
  }

  render() {
    const { children } = this.props

    return children
  }
}

const mapStateToProps = (state: RootState) => ({
  token: state.auth.credentials.merchant_access_token,
  redirect_denied: state.auth.auth.redirect_denied,
})

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { history }: PreloadProps,
) => ({
  onAuth: () => history.replace('/auth/sign-in'),
  onMain: () => history.replace('/'),
  onFetchLastSuccessfulUpload: () => dispatch(fetchLastSuccessfulUpload()),
  onFetchContacts: () => dispatch(fetchContacts()),
  onFetchProfile: () => dispatch(fetchProfile()),
  onFetchCities: () => dispatch(fetchCities()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PreloadProvider)
