import { useEffect } from 'react'
import { useAppDispatch } from 'store/hooks'

import { fetchPickupPoints } from 'modules/profile/slices/pickupPoints'
import { fetchOrderDetails } from 'modules/orders/slices/orders'

export const useOrderDetails = (orderId: string) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (orderId) {
      dispatch(fetchPickupPoints())
      dispatch(
        fetchOrderDetails({
          refresh: true,
          orderId,
        }),
      )
    }
  }, [orderId, dispatch])
}
