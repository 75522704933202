import { FC } from 'react'

import { Typography, Breadcrumbs, Link, Box } from 'ui'

interface OrderDetailsTitleProps {
  orderId: string
}

const { Title, Body } = Typography

const OrderDetailsTitle: FC<OrderDetailsTitleProps> = ({ orderId }) => (
  <Box>
    <Title as='p' mb='16px'>
      Заказ №{orderId}
    </Title>

    <Breadcrumbs mb='24px'>
      <Link to='/orders'>
        Список заказов
      </Link>

      <Body>Заказ №{orderId}</Body>
    </Breadcrumbs>
  </Box>
)

export { OrderDetailsTitle }
