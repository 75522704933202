import { FC } from 'react'

import { LogoIcon } from 'ui/icons'
import { Box } from 'ui'

const SignInHeader: FC = () => (
  <Box mb='12px'>
    <LogoIcon />
  </Box>
)

export default SignInHeader
