import { useLocation } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from 'store/hooks'

import { toggleAside } from 'modules/common/slices/asideMenu'

import { MenuItem, ExpandMenuButton } from './components'
import { Aside, Nav, StyledCaption } from './styles'
import {
  OrdersIcon,
  PlateIcon,
  UploadIcon,
  InstallmentIcon,
  FinanceIcon,
  TimeIcon,
  QuestionIcon,
  SettingsIcon,
  ReplayIcon as RefundsIcon,
} from 'ui/icons'

import { env } from 'settings/env'

import type { FC } from 'react'

const paths = [
  '/orders',
  '/products',
  '/products/upload',
  '/refunds',
  '/installments',
  '/finance',
  '/faq',
  '/profile/pickup-points',
  '/profile/settings',
].reverse()

const AsideMenu: FC = () => {
  const dispatch = useAppDispatch()
  const { isOpen } = useAppSelector(state => state.common.aside)
  const { pathname } = useLocation()

  const handleToggle = () => {
    dispatch(toggleAside())
  }

  const activePathName = paths.find(path => pathname.indexOf(path) > -1)

  return (
    <Aside isOpen={isOpen}>
      <Nav isOpen={isOpen}>
        <ExpandMenuButton
          caption='Заказы и возвраты'
          isOpen={isOpen}
          onClick={handleToggle}
        />

        <ul>
          <MenuItem
            isOpen={isOpen}
            title='Заказы'
            to='/orders'
            icon={OrdersIcon}
            isActive={activePathName === '/orders'}
          />
          {env.STAND === 'test' && (
            <MenuItem
              isOpen={isOpen}
              title='Возвраты'
              to='/refunds'
              icon={RefundsIcon}
              isActive={activePathName === '/refunds'}
            />
          )}
        </ul>

        <StyledCaption as='h3'>
          {isOpen && 'Товары и их настройка'}
        </StyledCaption>

        <ul>
          <MenuItem
            isOpen={isOpen}
            title='Товары'
            to='/products'
            icon={PlateIcon}
            isActive={activePathName === '/products'}
          />
          <MenuItem
            isOpen={isOpen}
            title='Загрузка прайс–листа'
            to='/products/upload/manual'
            icon={UploadIcon}
            isActive={activePathName === '/products/upload'}
          />
        </ul>

        <StyledCaption as='h3'>{isOpen && 'Акции и финансы'}</StyledCaption>
        <ul>
          <MenuItem
            isOpen={isOpen}
            title='Акции'
            to='/installments'
            icon={InstallmentIcon}
            isActive={activePathName === '/installments'}
          />
          <MenuItem
            isOpen={isOpen}
            title='Финансы'
            to='/finance'
            icon={FinanceIcon}
            isActive={activePathName === '/finance'}
          />
        </ul>

        <StyledCaption as='h3'>{isOpen && 'Остальное'}</StyledCaption>
        <ul>
          <MenuItem
            isOpen={isOpen}
            title='График работы'
            to='/profile/pickup-points'
            icon={TimeIcon}
            isActive={activePathName === '/profile/pickup-points'}
          />
          <MenuItem
            isOpen={isOpen}
            title='Помощь и инструкции'
            to='/faq'
            icon={QuestionIcon}
            isActive={activePathName === '/faq'}
          />
          <MenuItem
            isOpen={isOpen}
            title='Настройки'
            to='/profile/settings'
            icon={SettingsIcon}
            isActive={activePathName === '/profile/settings'}
          />
        </ul>
      </Nav>
    </Aside>
  )
}

export { AsideMenu }
