import { Box, Button, Typography } from 'ui'
import { Repairing } from 'ui/icons/Illustrations'
import { useAppSelector } from 'store/hooks'

import { ContactType } from 'modules/common/types'

import type { FC } from 'react'

const { Body } = Typography

const AccountStatus: FC = () => {
  const phone = useAppSelector(
    state =>
      state.common.contacts.list.find(
        contact => contact.type === ContactType.Phone,
      )?.comment,
  )

  return (
    <Box>
      <Body as='p' mb='8px'>
        Мы собираем ваш аккаунт. По готовности менеджер свяжется с вами
      </Body>

      {phone && (
        <Body as='p' mb='24px' color='text.secondaryBlack'>
          Единый контакт центр: {phone}
        </Body>
      )}

      <Repairing />

      <Button mt='24px' fullWidth onClick={() => window.location.reload()}>
        Вернуться ко входу
      </Button>
    </Box>
  )
}

export default AccountStatus
