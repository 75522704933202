import { useState } from 'react'
import { useIsOrderAccepted } from 'modules/orders/hooks'

import { Typography, Box, Button, Modal, Table as BaseTable } from 'ui'
import { useAppTheme } from 'ui/theme'

import { TableActions } from './components'
import { columns } from './columns'

import { ORDERS_FILTER_STATUS } from 'modules/orders/types/enums'

import type { ChangeEvent, FC } from 'react'
import type { OrderProductsType, OrderType } from 'modules/orders/types'
import type { CbxState } from 'modules/common/types/interfaces'
import type { ChangeCbx } from 'modules/common/types'

export type ModalType = {
  visible: boolean
  onAccept: VoidFunction
}

interface TableProps {
  isCollapsed?: boolean
  products: OrderProductsType[]
  onChangeCbx: ChangeCbx
  details: OrderType
  cbxState: CbxState
  onResetCbx: VoidFunction
  onCheckAllCbxChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const Table: FC<TableProps> = ({
  details,
  cbxState,
  products,
  isCollapsed,
  onChangeCbx,
  onResetCbx,
  onCheckAllCbxChange,
}) => {
  const isOrderAccepted = useIsOrderAccepted()
  const theme = useAppTheme()

  const [modal, setModal] = useState<ModalType>({
    visible: false,
    onAccept: null,
  })

  const condition =
    details.storage_type === 'fbo' ||
    !details.payment.dividable ||
    isOrderAccepted

  const closeModal = () => {
    setModal({
      visible: false,
      onAccept: null,
    })
  }

  const getColumns = () => {
    const props = {
      cbxState,
      onChangeCbx,
      products,
      setModal,
      condition,
      onCheckAllCbxChange,
    }

    return condition ? columns(props).slice(1) : columns(props)
  }

  return (
    <Box borderTop={!isCollapsed && `1px solid ${theme.greyColorPalette[10]}`}>
      <BaseTable
        rowKey='sku'
        data={products}
        sticky={false}
        columns={getColumns()}
        showRenderGeneralActions={cbxState.checkedList.length > 0}
        renderRowActions={
          details.status === ORDERS_FILTER_STATUS.WAITING_CONFIRMATION &&
          details.payment.dividable
            ? row => (
                <TableActions
                  row={row}
                  cbxState={cbxState}
                  products={products}
                  onResetCbx={onResetCbx}
                />
              )
            : null
        }
        renderGeneralActions={
          <TableActions
            cbxState={cbxState}
            products={products}
            onResetCbx={onResetCbx}
          />
        }
      />

      <Modal
        title='Изменение заказа'
        visible={modal.visible}
        onClose={closeModal}
        width='365px'
      >
        <Typography.Body as='p'>
          Если измените количество товаров, у вас снизится рейтинг.
        </Typography.Body>
        <Typography.Body as='p' mb='24px' color='text.link'>
          Подробнее про рейтинг
        </Typography.Body>

        <Box display='flex' justifyContent='flex-end'>
          <Button mr='16px' variant='default' onClick={closeModal}>
            Отмена
          </Button>
          <Button
            variant='secondary'
            onClick={() => {
              modal.onAccept()
              closeModal()
            }}
          >
            Всё равно изменить
          </Button>
        </Box>
      </Modal>
    </Box>
  )
}

export { Table }
