import { useAppDispatch } from 'store/hooks'

import { generatePdf } from 'modules/orders/slices/orders'

import { TableOverlayButton } from 'ui/table/components'
import { DocumentIcon, OrdersIcon, PromoIcon } from 'ui/icons'
import { Box, Tooltip, Typography } from 'ui'
import { useAppTheme } from 'ui/theme'

import type { FC } from 'react'
import type { OrderType } from 'modules/orders/types'

interface Props {
  row: OrderType
  changeOrderPackages: (orderId: string) => void
}

const OrdersTableAssembledActions: FC<Props> = ({
  row,
  changeOrderPackages,
}) => {
  const theme = useAppTheme()
  const dispatch = useAppDispatch()

  return (
    <Box display='flex'>
      <Tooltip
        placement='top'
        overlay={
          <Typography as='p' textAlign='center' lineHeight='m'>
            Скачать <br /> стикеры
          </Typography>
        }
      >
        <TableOverlayButton
          onClick={() => {
            dispatch(
              generatePdf({
                docType: 'STICKER',
                orderId: row.id,
              }),
            )
          }}
        >
          <PromoIcon color={theme.colors.icon.black} />
        </TableOverlayButton>
      </Tooltip>
      <Tooltip
        placement='top'
        overlay={
          <Typography as='p' textAlign='center' lineHeight='m'>
            Скачать акт <br /> приёма передач
          </Typography>
        }
      >
        <TableOverlayButton
          onClick={() => {
            dispatch(
              generatePdf({
                docType: 'ACT',
                orderId: row.id,
              }),
            )
          }}
        >
          <DocumentIcon color={theme.colors.icon.black} />
        </TableOverlayButton>
      </Tooltip>
      <Tooltip
        placement='top'
        overlay={
          <Typography as='p' textAlign='center' lineHeight='m'>
            Указать количество <br /> грузовых мест
          </Typography>
        }
      >
        <TableOverlayButton onClick={() => changeOrderPackages(row.id)}>
          <OrdersIcon color={theme.colors.icon.black} />
        </TableOverlayButton>
      </Tooltip>
    </Box>
  )
}

export { OrdersTableAssembledActions }
