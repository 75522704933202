import { FC } from 'react'

import { usePartnerSelection } from 'modules/auth/hooks'

import { capitalize } from 'utils/textFormatting'
import { ROLES } from 'modules/common/constants/dictionaries'

import { Typography, Button, Image, Anchor, Box, Ellipsis } from 'ui'
import { ChevronIcon } from 'ui/icons'
import { useAppTheme } from 'ui/theme'

import profileImageScr from 'assets/images/profile.png'

import {
  Partners,
  Partner,
} from 'modules/auth/components/PartnersSelection/styles'

import { env } from 'settings/env'

const { Subtitle, Caption, Body } = Typography

const PartnersSelection: FC = () => {
  const { onSelectPartner, scrollable, partners } = usePartnerSelection()
  const theme = useAppTheme()

  return (
    <Box>
      <Subtitle as='p' mb='16px'>
        Выберите магазин
      </Subtitle>

      <Partners scrollable={scrollable}>
        {partners.map(partner => (
          <Partner key={partner.mid} onClick={onSelectPartner(partner)}>
            <Box display='flex' alignItems='center'>
              <Image
                src={partner.logo_url || profileImageScr}
                borderRadius='r'
                minWidth={40}
                height={40}
                width={40}
                isBg
              />

              <Box ml='16px'>
                <Ellipsis as={Body}>{partner.store_name || '-'}</Ellipsis>

                <Ellipsis as={Caption} color='text.secondaryBlack'>
                  {capitalize(
                    partner.roles
                      .map(role => ROLES[role] || 'сотрудник')
                      .join(', '),
                  )}
                </Ellipsis>
              </Box>
            </Box>

            <Box display='flex' alignItems='center' minWidth='20px'>
              <ChevronIcon right color={theme.colors.icon.secondaryBlack} />
            </Box>
          </Partner>
        ))}
      </Partners>

      <Anchor
        href={env.PARTNER_BASE_URL}
        target='_blank'
        className='__create-partner-button'
      >
        <Button fullWidth>Создать другой магазин</Button>
      </Anchor>
    </Box>
  )
}

export default PartnersSelection
